import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
const pinia = createPinia();

declare global {
	function route(name?: string, params?: object): any;
}

// @ts-ignore
let pages = import.meta.glob('./Pages/**/*.vue');

void createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, pages),
	setup({ el, app, props, plugin }) {
		const app1 = createApp({ render: () => h(app, props) });

		// integrate sentry if not in development mode
		// @ts-ignore
		if (import.meta.env.MODE !== 'development') {
			console.log('init sentry');
			Sentry.init({
				app: app1,
				dsn: 'https://c9e972554b1a4f31b4c841754e9af9f9@o4504723831652352.ingest.sentry.io/4504723839451136',
				integrations: [
					new BrowserTracing({
						tracePropagationTargets: ['pa2go.test', 'patogo-test.at', 'patogo.app', /^\//],
					}),
				],
				logErrors: true,
				// Set tracesSampleRate to 1.0 to capture 100%
				// of transactions for performance monitoring.
				// We recommend adjusting this value in production
				tracesSampleRate: 1.0,
			});
		}

		app1.use(plugin);
		app1.use(ZiggyVue);
		app1.use(pinia);

		app1.mount(el);
		return app1;
	},
});

InertiaProgress.init({ color: '#4B5563' });
